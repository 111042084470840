import { Fragment, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { selectTrim } from "../../../screens/vehicleDetails/vehicleDetails.analytics.js";
import "../../../styles/scss/vp-header/vp-trim-selector.scss";

const TrimSelector = ({ trimsList, selectedTrim, setSelectedTrim, analyticsData }) => {
    const [isLoading, setIsLoading] = useState(true);

    // Update the isLoading state based on the trimsList
    useEffect(() => {
        if (trimsList && trimsList.length > 0) {
            setIsLoading(false);
        }
    }, [trimsList]);

    const powertrainCategories = Object.keys(trimsList.reduce((acc, curr) => {
        if (curr.powertrainCategories) {
            curr.powertrainCategories.forEach((category) => {
                if (!acc[category]) {
                    acc[category] = true;
                }
            });
        }
        return acc;
      }, {}));

    return (
        <div className={`trim-selector-container ${powertrainCategories.length && !isLoading ? "has-powertrain-category" : ""}`}>
            {isLoading ? (
                 <div className="trim-selector-skeleton-container">
                 <Skeleton
                   width={80}
                   height={20}
                   count={5}
                 />
               </div>
            ) : powertrainCategories.length ? (
                powertrainCategories.map((powertrainCategory) => {
                    return (
                        <Fragment key={powertrainCategory}>
                            <div className="trim-item-header">{powertrainCategory} Models</div>
                            <div className="trim-item-container">
                                {trimsList
                                    .filter((trim) => trim.powertrainCategories.includes(powertrainCategory))
                                    .map((trim) => {
                                        const isSelected =
                                            trim.name === selectedTrim?.trim?.name &&
                                            powertrainCategory === selectedTrim?.powertrainCategory;
                                        return (
                                            <div
                                                key={trim.name}
                                                className={`trim-item ${isSelected ? "trim-item-selected" : ""}`}
                                                onClick={() => {setSelectedTrim(trim, powertrainCategory); sendAnalytics(analyticsData, trim.name)}}
                                                role="button"
                                            >
                                                <span className={isSelected ? "selected" : ""}>{trim.name}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Fragment>
                    )
                })
            ) :(
                trimsList.map((trim) => {
                    const isSelected = trim.name === selectedTrim.trim.name;
                    return (
                        <div
                            key={trim.name}
                            className={`trim-item ${isSelected ? "trim-item-selected" : ""}`}
                            onClick={() => {setSelectedTrim(trim); sendAnalytics(analyticsData, trim.name)}}
                            role="button"
                        >
                            <span className={isSelected ? "selected" : ""}>{trim.name}</span>
                        </div>
                    )
                })
            )}
        </div>
    )
}

function sendAnalytics(analyticsData, selectedTrim){
    selectTrim({
        page: analyticsData?.page,
        contentTitle: selectedTrim,
        modelName: analyticsData?.modelName,
        modelYear: analyticsData?.modelYear
    })
}

export default TrimSelector;
